@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  60% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  opacity: 0; /* Initial opacity set to 0 */
  transform: scale(0.5); /* Initial scale set to 0.5 */
  transition: all 0.3s ease-in-out; /* Smooth transition */
}

.badge.animate {
  animation: fadeInScaleUp 0.5s ease-in-out forwards; /* Trigger the animation */
}

.badge.completed {
  background-color: #4ca0af;
}

.badge.incomplete {
  background-color: #ccc;
}

.badge-icon {
  margin-right: 10px;
}
