.tasks-container {
  background-color: #f4f4f8;
  padding: 20px;
  border-radius: 12px;
  width: 300px;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 1.2rem;
  margin-bottom: 8px;
}

p {
  font-size: 0.9rem;
  margin-bottom: 16px;
  color: #666;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin: 8px 0;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

input[type='checkbox'] {
  margin-right: 10px;
}

.completed label {
  text-decoration: line-through;
  color: #888;
}
